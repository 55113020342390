var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_wrap"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"consult_card_wrap"},[_c('ConsultCard',{attrs:{"consultList":_vm.consultList}})],1),_vm._m(2),_c('div',{staticClass:"func_wrap hidden-xs-only",staticStyle:{"background":"#f4f9fc"}},[_vm._m(3),_c('div',{staticClass:"double_card_wrap"},[_c('el-row',{staticClass:"double_card",attrs:{"type":"flex"}},[_c('el-col',{staticClass:"double_card_item",attrs:{"span":7,"xs":11}},[_c('div',{staticClass:"double_card_bg"},[_vm._v("数据驱动")]),_c('p',[_vm._v("基于产业链，创新链，空间链，链的数据优势，提供专业化地区生物医药产业数据评价服务。")])]),_c('el-col',{staticClass:"double_card_item",attrs:{"span":7,"xs":11}},[_c('div',{staticClass:"double_card_bg"},[_vm._v("一体化解决方案")]),_c('p',[_vm._v("形成“产业研究+规划蓝图+资源集成+平台落地”的理论指导，提供“顶天立地”独特的区域生物医药产业研究解决方案。")])]),_c('el-col',{staticClass:"double_card_item",attrs:{"span":7,"xs":11}},[_c('div',{staticClass:"double_card_bg"},[_vm._v("专业服务团队")]),_c('p',[_vm._v("聚焦国内规模最大的生物医药产业研究专业团队。 核心骨干人员均拥有多年生物医药行业研究和咨询规划工作经验")])])],1)],1)]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"product_shows_wrap"},[_vm._m(6),_c('div',{staticClass:"with_center"},[_c('div',{staticClass:"bottom_img_wrap"},_vm._l((_vm.procardList),function(item,index){return _c('div',{key:index,staticClass:"bottom_img_item"},[_c('div',{staticClass:"bottom_img"},[_c('img',{attrs:{"src":item.src,"alt":""}}),_c('div',{staticClass:"img_desc"},[_vm._v(_vm._s(item.tit))])])])}),0)])]),_c('CallMe'),_c('BoottomTab')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_img"},[_c('div',{staticClass:"img_tit"},[_vm._v("产业咨询服务")]),_c('div',{staticClass:"img_desc"},[_vm._v(" 为生物医药产业发展提供一站式战略咨询服务 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_title_wrap"},[_c('div',{staticClass:"consult_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center",staticStyle:{"width":"40%"}},[_c('div',{staticClass:"consult_title"},[_vm._v("ESOO产业咨询服务介绍")]),_c('div',{staticClass:"consult_desc"},[_vm._v(" 提供产业研究，规划，评估，监测等战略咨询服务 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_title_wrap"},[_c('div',{staticClass:"consult_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center",staticStyle:{"width":"40%"}},[_c('div',{staticClass:"consult_title"},[_vm._v("ESOO产业咨询服务独特优势")]),_c('div',{staticClass:"consult_desc"})]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"func_wrap_inner"},[_c('div',{staticClass:"func_tit"},[_vm._v("BioMall 四大核心价值")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"func_wrap_mobile hidden-sm-and-up"},[_c('img',{attrs:{"src":require("@/assets/img/ys.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appointment_wrap"},[_c('div',{staticClass:"appointment_txt"},[_c('h1',[_vm._v("预约我们的产业专家"),_c('br'),_vm._v("了解更多有关ESOO产业咨询服务详细信息")]),_c('p',[_vm._v("拨打我们的咨询热线 000-000-0000")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consult_title_wrap"},[_c('div',{staticClass:"consult_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"consult_title"},[_vm._v("客户见证")]),_c('div',{staticClass:"consult_desc"},[_vm._v(" 全国各地150+与产业园合作成功案例 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }